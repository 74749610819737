import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { AvatarSvg, UserSvg } from '@assets/svgs';
import { userSelector } from '@store';

import { OverlayMenu, Text, MenuItem, ItemsDivider, DropdownBase } from './DropdownBase';
import { ChangePasswordModal } from './ChangePasswordModal';
import { MouseEventHandler, useState } from 'react';
import { Menu } from 'antd';
import { truncate } from '@helpers/utils';

const Container = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const WrapUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const IconAvatar = styled(AvatarSvg)`
  width: 40px;
  path {
    fill: ${({ theme }) => theme.colors.text.main};
  }
`;

const TextName = styled(Text)`
  color: unset;
  cursor: unset;
  line-height: 1;
`;

export const DropdownProfile: React.FC = () => {
  const user = useSelector(userSelector.selectUserData);
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const { t } = useTranslation();

  const onClickChangePassword = () => {
    setShowModalChangePassword(true);
  };

  return Boolean(user) ? (
    <>
      <DropdownBase
        overlay={
          <Menu
            items={[
              {
                key: '1',
                label: (
                  <WrapUser>
                    <IconAvatar />
                    <TextName>{truncate(user?.email || '', 25)}</TextName>
                  </WrapUser>
                ),
              },
              ...(user?.type === 'public'
                ? [
                    {
                      key: '2',
                      label: <Text>Change password</Text>,
                      onClick: onClickChangePassword,
                    },
                  ]
                : []),
              {
                key: '3',
                label: (
                  <Text>
                    <Link to="/logout">Logout</Link>
                  </Text>
                ),
              },
            ]}
          />
        }
      >
        <Container>
          <UserSvg />
        </Container>
      </DropdownBase>
      <ChangePasswordModal setVisible={setShowModalChangePassword} visible={showModalChangePassword} />
    </>
  ) : null;
};
