import { Rule } from 'antd/lib/form';
import { WheelEventHandler } from 'react';

import { TRAIN_PARAMS } from '@constants';
import { captalize } from '@helpers/utils';

export const generateRangeRule: (name: string) => Rule = (name) => ({
  validator: async (_, value) => {
    const data = TRAIN_PARAMS[name];
    const range = data.range || [0, 0];
    const nvalue = Number(value);
    if (nvalue < range[0]) return Promise.reject(new Error(`${captalize(name)} must be greater than ${range[0]}`));
    else if (nvalue > range[1]) return Promise.reject(new Error(`${captalize(name)} must be less than ${range[1]}`));
    return Promise.resolve();
  },
});

export const onWheel: WheelEventHandler<HTMLInputElement> = (e) => (e.target as any).blur();
