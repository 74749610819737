import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import styled from 'styled-components';
import { Tooltip, Typography } from 'antd';

import { DeviceModel } from '@types';
import { snakeCaseToTitle } from '@helpers/utils';
import { ButtonConfirm, TextEllipsis } from '@components';

import { WrapTableActions } from './styles';
import { ButtonEdit } from './components';

type Params = { onClickUnregisted: (device: DeviceModel) => Promise<void>; deleteIds: string[]; onClickUndeploy: (device: DeviceModel) => Promise<void>; undeployIds: string[]; fetchData: () => Promise<void>; isCanUpdate?: boolean };

const TextNonwrap = styled.div`
  white-space: nowrap;
`;

const TextName = styled(TextEllipsis)`
  min-width: 200px;
`;

const TextNameZone = styled(TextEllipsis)`
  min-width: 150px;
`;

const TextDeployment = styled(TextEllipsis)`
  min-width: 150px;
`;

export const getColumns: (params: Params) => ColumnsType<DeviceModel> = ({ onClickUnregisted, deleteIds, onClickUndeploy, undeployIds, fetchData, isCanUpdate }) => [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
    sorter: true,
    render: (o) => (
      <Tooltip title={o}>
        <Typography.Paragraph style={{ width: 140, marginBottom: 0 }} copyable ellipsis={{ rows: 1 }}>
          {o}
        </Typography.Paragraph>
      </Tooltip>
    ),
  },
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
    width: 240,
    render: (o) => <TextName line={1}>{o}</TextName>,
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (status: string) => <span>{snakeCaseToTitle(status)}</span>,
    sorter: true,
    width: 110,
  },
  {
    key: 'registerd',
    title: 'Registerd Time',
    dataIndex: 'created_at',
    render: (created_at: string) => <TextNonwrap>{moment.unix(Number(created_at)).format('MMMM Do YYYY, h:mm A')}</TextNonwrap>,
    defaultSortOrder: 'descend',
    sorter: true,
    width: 240,
  },
  {
    key: 'ip',
    title: 'IP Address',
    dataIndex: 'ip_address',
    width: 150,
  },
  {
    key: 'zone',
    title: 'Zone',
    dataIndex: 'zone',
    sorter: true,
    render: (o) => <TextNameZone line={1}>{o}</TextNameZone>,
  },
  {
    key: 'location',
    title: 'Location',
    dataIndex: 'location',
    sorter: true,
    render: (o) => <TextNonwrap>{o}</TextNonwrap>,
  },
  {
    key: 'pipeline_name',
    title: 'Deployment',
    dataIndex: 'pipeline_name',
    render: (o) => <TextDeployment line={1}>{o}</TextDeployment>,
  },
  ...(isCanUpdate !== false
    ? [
        {
          key: 'actions',
          title: 'Actions',
          width: 220,
          render: (v: any, o: any) => (
            <WrapTableActions>
              {o.status === 'deployed' && (
                <ButtonConfirm
                  danger
                  confirm={{
                    title: 'Are you sure?',
                    onClickOk: () => onClickUndeploy(o),
                    confirmLoading: undeployIds.some((oo) => oo === o.id),
                    okType: 'danger',
                    children: 'Are you sure to undeploy the model from this device?',
                  }}
                >
                  Undeploy
                </ButtonConfirm>
              )}
              <ButtonConfirm
                danger
                confirm={{
                  title: 'Are you sure?',
                  onClickOk: () => onClickUnregisted(o),
                  confirmLoading: deleteIds.some((oo) => oo === o.id),
                  okType: 'danger',
                  children: 'Are you sure to unregister this device?',
                }}
              >
                Unregisted
              </ButtonConfirm>
              <ButtonEdit fetchData={fetchData} data={o} />
            </WrapTableActions>
          ),
        },
      ]
    : []),
];
