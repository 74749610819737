import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Col, Row, Spin } from 'antd';

import { devicesActions, devicesSelector, pipelinesActions, pipelinesSelector, projectSelector } from '@store';
import { PageTitle, Breadcrumb, BreadcrumbItem, LoadingContainer } from '@components';
import { FullscreenExitSvg, FullscreenSvg } from '@assets/svgs';
import { DeviceModel, Pagination, Response } from '@types';

import { PageMainHeading } from '../../styles';

import { CardChart, CardStat } from './components';
import { Wrapper, ButtonFullscreen, WrapHead, Content } from './styles';

export const DeploymentOverview: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const projectItem = useSelector(projectSelector.selectItem);
  const models = useSelector(pipelinesSelector.selectList);
  const devices = useSelector(devicesSelector.selectList);
  const handle = useFullScreenHandle();
  const [devicesDeployed, setDeviceDeployed] = useState<DeviceModel[]>([]);
  const [numDeviceTotal, setNumDeviceTotal] = useState<number>();
  const [numDeviceDeployed, setNumDeviceDeployed] = useState<number>();
  const [numDeviceUnreach, setNumDeviceUnreach] = useState<number>();
  const [loadingDeviceDeployed, setLoadingDeviceDeployed] = useState(false);

  useEffect(() => {
    if (!projectItem.result?.id) return;
    setDeviceDeployed([]);
    setLoadingDeviceDeployed(true);
    dispatch(pipelinesActions.fetchMany({ params: { project_id: projectItem.result.id } }));
    dispatch(
      devicesActions.fetchMany({
        params: { project_id: projectItem.result.id },
        onSuccess: (res: Response<Pagination<DeviceModel>>) => {
          setNumDeviceTotal(res.data.pagination.totalRecords);
        },
      })
    );
    dispatch(
      devicesActions.fetchMany({
        params: { project_id: projectItem.result.id, filterby: 'status', filtervalue: 'deployed' },
        onSuccess: (res: Response<Pagination<DeviceModel>>) => {
          setDeviceDeployed((state) => [...res.data.items, ...state]);
          setNumDeviceDeployed(res.data.pagination.totalRecords);
        },
      })
    );
    dispatch(
      devicesActions.fetchMany({
        params: { project_id: projectItem.result.id, filterby: 'status', filtervalue: 'unreachable' },
        onSuccess: (res: Response<Pagination<DeviceModel>>) => {
          setDeviceDeployed((state) => [...res.data.items, ...state]);
          setNumDeviceUnreach(res.data.pagination.totalRecords);
          setLoadingDeviceDeployed(false);
        },
      })
    );
  }, [projectItem]);

  const onClickFullscreen = () => {
    if (handle.active) handle.exit();
    else handle.enter();
  };

  return (
    <FullScreen handle={handle}>
      <Wrapper fullscreen={String(handle.active)}>
        <PageTitle>Deployment Overview</PageTitle>
        <Breadcrumb>
          <BreadcrumbItem href="/workspace">Home</BreadcrumbItem>
          <BreadcrumbItem href="/workspace/deployment">Development</BreadcrumbItem>
          <BreadcrumbItem>Overview</BreadcrumbItem>
        </Breadcrumb>
        <WrapHead>
          <PageMainHeading>Deployment Overview</PageMainHeading>
          <ButtonFullscreen type="text" onClick={onClickFullscreen}>
            {handle.active ? <FullscreenExitSvg /> : <FullscreenSvg />}
          </ButtonFullscreen>
        </WrapHead>
        <Content>
          <Row gutter={[16, 16]}>
            <CardStat
              loading={models.loading}
              items={[
                { title: 'Model available to deploy', text: models.result?.pagination?.totalRecords?.toString() || '0' },
                { title: 'Active models', text: models.result?.pagination?.totalRecords?.toString() || '0' },
              ]}
              link={{ title: 'View & Deploy', to: '/workspace/deployment/model-device' }}
            />
            <CardStat
              loading={devices.loading}
              items={[
                { title: 'Devices Registered', text: typeof numDeviceTotal === 'number' ? numDeviceTotal.toString() : '0' },
                { title: 'Online', text: typeof numDeviceDeployed === 'number' ? numDeviceDeployed.toString() : '0' },
                { title: 'Offline', text: typeof numDeviceTotal === 'number' && typeof numDeviceDeployed === 'number' && typeof numDeviceUnreach === 'number' ? (numDeviceTotal - numDeviceDeployed).toString() : '0' },
              ]}
              link={{ title: 'Manage', to: '/workspace/deployment/model-device/devices' }}
            />
          </Row>
          <Row gutter={[16, 16]}>
            {loadingDeviceDeployed && (
              <Col xs={24}>
                <LoadingContainer>
                  <Spin />
                </LoadingContainer>
              </Col>
            )}
            {devicesDeployed
              .filter((o) => Boolean(o.pipeline_id))
              .map((o, i) => (
                <CardChart key={i} device={o} />
              ))}
          </Row>
        </Content>
      </Wrapper>
    </FullScreen>
  );
};
