import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';

import { Empty } from '@components';
import { PipelineModel } from '@types';
import { pipelinesActions, projectSelector } from '@store';
import { useScrollContent } from '@helpers/hooks';

import { Item } from './components';
import { List, WrapSpin } from './styles';

export const ModelContent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const projectItem = useSelector(projectSelector.selectItem);

  const fetch = useCallback(
    (page: number, size: number) =>
      new Promise((res) => {
        if (projectItem.result?.id) dispatch(pipelinesActions.fetchMany({ params: { project_id: projectItem.result.id, page: page, size: size, filterby: 'status', filtervalue: 'Done' }, onSuccess: res }));
      }) as any,
    [projectItem.result?.id]
  );

  const { loading, data, fetchData } = useScrollContent<PipelineModel>({
    size: 10,
    queries: '.ant-layout-content',
    handle: ({ fetchMore }) => fetchMore(),
    fetch: fetch,
    handleData: (items) => (state) => [...state, ...items.filter((o) => state.every((oo) => oo.id !== o.id))],
  });

  const pipelineAvaiable = data.filter((o) => o.status === 'Done');

  return (
    <>
      {pipelineAvaiable.length ? (
        <>
          <List>
            {pipelineAvaiable.map((o) => (
              <Item key={o.id} data={o} fetchData={fetchData} />
            ))}
          </List>
          {loading && (
            <WrapSpin>
              <Spin />
            </WrapSpin>
          )}
        </>
      ) : (
        <Empty loading={loading} />
      )}
    </>
  );
};
