import styled from 'styled-components';

import { Back as UIBack } from '@components';

export const WrapperBody = styled.div`
  padding: 20px 0;
  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const WrapperStep = styled.div`
  display: flex;
  justify-content: center;

  .ant-steps-item-active .ant-steps-item-title {
    color: ${({ theme }) => theme.colors.main.primary} !important;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 10px 0;
`;

export const Back = styled(UIBack)`
  margin-bottom: 15px;
`;
