import { MouseEventHandler, ReactNode, useEffect, useRef, useState } from 'react';
import { Button, Col, Dropdown, Menu, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ThreeDotsSvg } from '@assets/svgs';
import { PipelineModel, PipelineStepStatus, Response } from '@types';
import { pipelinesActions, projectSelector } from '@store';
import { snakeCaseToTitle } from '@helpers/utils';
import { ModalConfirm } from '@components';

import { WrapItem, TextHead, TextTitle, ContentItem, Wrapper, Header, TextName, ButtonMenu } from './styles';

type PropsItem = {
  head?: string;
  title: string;
  content: ReactNode;
  status?: PipelineStepStatus;
};
export const Item: React.FC<PropsItem> = ({ content, head, title, status }) => (
  <Col xs={12} sm={8} md={6} lg={6}>
    <WrapItem>
      {Boolean(head) && <TextHead>{head}</TextHead>}
      <TextTitle>{title}</TextTitle>
      <ContentItem status={status}>{content}</ContentItem>
    </WrapItem>
  </Col>
);

type Props = {
  data: PipelineModel;
  fetchData: () => Promise<void>;
};

export const ModelItem: React.FC<Props> = ({ data, fetchData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [dataPipeline, setDataPipeline] = useState<PipelineModel>();
  const refAPIInterval = useRef<any>();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const isSample = useSelector(projectSelector.selectProjectIsSample);

  const clearAPIInterval = () => {
    if (refAPIInterval.current) {
      clearInterval(refAPIInterval.current);
      refAPIInterval.current = undefined;
    }
  };

  const createAPIInterval = () => {
    clearAPIInterval();
    if (data.status === 'Cancelled' || data.status === 'Failed' || data.status === 'Done') return;
    refAPIInterval.current = setInterval(async () => {
      const response: Response<PipelineModel> = await new Promise((res) => dispatch(pipelinesActions.fetch({ params: { id: data.id }, onSuccess: res })));
      setDataPipeline(response.data);
      if (response.data.status === 'Cancelled' || response.data.status === 'Failed' || response.data.status === 'Done') clearAPIInterval();
    }, 10000);
  };

  const onClickItem: MouseEventHandler<HTMLDivElement> = () => {
    navigate(`/workspace/models/${data.id}`);
  };

  const onClickMenu: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
  };

  const onClickDelete = async () => {
    setLoadingDelete(true);
    await new Promise((res) => dispatch(pipelinesActions.remove({ params: { id: data.id }, onEnd: res })));
    setLoadingDelete(false);
    fetchData();
  };

  useEffect(() => {
    if (!data) return;
    setDataPipeline(data);
    createAPIInterval();

    return () => clearAPIInterval();
  }, [data]);

  return (
    <>
      <Wrapper onClick={onClickItem} loading={String(loadingDelete)}>
        <Header>
          <TextName>{data.pipeline_name}</TextName>
          {!Boolean(isSample) && (
            <Dropdown
              overlay={
                <Menu
                  items={[
                    {
                      key: '1',
                      onClick: (e) => {
                        e.domEvent.stopPropagation();
                        setShowModalDelete(true);
                      },
                      label: (
                        <Button type="text" danger>
                          Delete
                        </Button>
                      ),
                    },
                  ]}
                />
              }
              placement="bottomLeft"
            >
              <ButtonMenu type="text" onClick={onClickMenu}>
                <ThreeDotsSvg />
              </ButtonMenu>
            </Dropdown>
          )}
        </Header>
        <Row gutter={10}>
          <Item title="Preprocessing" content={snakeCaseToTitle(dataPipeline?.preprocessing_status || '')} status={dataPipeline?.preprocessing_status} />
          <Item title="Trainning" content={snakeCaseToTitle(dataPipeline?.training_status || '')} status={dataPipeline?.training_status} />
          <Item title="Evaluating" content={snakeCaseToTitle(dataPipeline?.evaluation_status || '')} status={dataPipeline?.evaluation_status} />
          <Item title="Status" content={snakeCaseToTitle(dataPipeline?.status || '')} status={dataPipeline?.status} />
          <Item title="Dataset name" content={dataPipeline?.dataset_name} />
          <Item title="Train container" content={dataPipeline?.train_container_name} />
          <Item title="Architecture" content={dataPipeline?.model_architecture_name} />
        </Row>
      </Wrapper>
      <ModalConfirm title="Are you sure?" visible={showModalDelete} setVisible={setShowModalDelete} onClickOk={onClickDelete} confirmLoading={loadingDelete} okType="danger">
        Do you really to delete this records?
      </ModalConfirm>
    </>
  );
};
