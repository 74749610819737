import styled from 'styled-components';

export const Card = styled.div<{ loading?: string }>`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 20px 24px;
  cursor: pointer;
  transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: ${({ loading }) => (loading === 'true' ? 0.5 : 1)};

  &:hover {
    border-color: ${({ theme }) => theme.colors.main.primary};
    background-color: ${({ theme }) => theme.colors.main.primary}0d;
  }
`;
