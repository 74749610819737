export const captalize = (text: string) => {
  let arr = text.split(' ');
  arr = arr.map((o) => o[0].toUpperCase() + o.slice(1));
  return arr.join(' ');
};

export const snakeCaseToTitle = (text: string) => {
  const arr = text.split('_');
  if (arr[0]) arr[0] = arr[0][0].toUpperCase() + arr[0].slice(1);
  return arr.join(' ');
};

export const truncate = (source: string, size: number) => {
  return source.length > size ? source.slice(0, size - 1) + '…' : source;
};
