import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { BurgerSvg, LogoSvg } from '@assets/svgs';
import { appActions } from '@store';

import { TopMenu, DropdownProfile } from './components';

const Wrapper = styled(Layout.Header)`
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  && {
    border-color: rgba(33, 33, 33, 0.08);
  }

  & > * {
    height: 100%;
  }

  & .cra-header-menu-right {
    & .ant-row,
    & .ant-col {
      height: 100%;
    }
  }

  & .cra-header-menu-right .ant-row .ant-col:last-child {
    padding-right: none !important;
  }
`;

const Burger = styled(BurgerSvg)`
  margin-right: 12px;
  cursor: pointer;
  display: none !important;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 0.2}px) {
    display: block !important;
  }
`;

const WrapperLeft = styled.div`
  &,
  & > * {
    display: flex;
    align-items: center;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    & > *:last-child:not(:first-child) {
      margin-left: 50px;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 0.2}px) {
    width: 100%;
  }
`;

const WrapperRight = styled.div`
  display: flex;
  align-items: center;
`;

export const Header: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isInWorkspace = /^\/workspace/g.test(location.pathname);
  const to = isInWorkspace ? '/workspace' : '/';

  const onClickBurger = () => dispatch(appActions.setSidebarDrawerVisible(true));

  return (
    <Wrapper className="header cra-main-header">
      <WrapperLeft>
        <>{isInWorkspace ? <Burger onClick={onClickBurger} /> : null}</>
        <Link to={to}>
          <LogoSvg />
        </Link>
        <>{isInWorkspace ? <TopMenu /> : null}</>
      </WrapperLeft>
      <WrapperRight>
        <DropdownProfile />
      </WrapperRight>
    </Wrapper>
  );
};
