import { Modal, Steps as UISteps } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Modal)``;

export const WrapperBody = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const WrapperJob = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Steps = styled(UISteps)`
  .ant-steps-item {
    flex: unset;
    &:not(:last-child) {
      margin-right: 60px;
    }
  }

  .ant-steps-item-title {
    font-size: 1.14rem;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0.15px;

    &::after {
      content: '';
      display: none;
    }
  }

  .ant-steps-item-icon {
    display: none;
  }

  .ant-steps-item-finish,
  .ant-steps-item-active {
    .ant-steps-item-title {
      color: ${({ theme }) => theme.colors.main.primary} !important;
    }
  }
`;

export const ItemInfoTitle = styled.p`
  flex: 1;
  &::after {
    content: ':';
  }
`;

export const ItemInfoContent = styled.p`
  flex: 3;
`;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 3.5px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px 15px;
`;

export const Content = styled.div`
  width: 100%;
  max-height: 70vh;
  overflow-y: scroll;
  padding: 10px 0;

  .ant-form-item {
    margin-bottom: 0;
  }

  & form .ant-form-item:not(:last-child),
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Title = styled.p`
  font-size: 1.14rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.15px;
  color: black;
`;
