import { Tooltip } from 'antd';
import styled from 'styled-components';

import { InfoSvg } from '@assets/svgs';
import { TrainItem } from '@types';

const WrapLabel = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 12px;
    fill: #00000099;
    margin-left: 4px;
  }
`;

export const LabelHint: React.FC<{ title: string; data: TrainItem }> = ({ title, data }) => {
  const hint = data.hint + (data.range ? `\nFor the solution running, below is the range ${data.range}` : '');

  return (
    <Tooltip title={hint} overlayInnerStyle={{ whiteSpace: 'pre-line' }}>
      <WrapLabel>
        {data.hint} {`(${title})`} <InfoSvg />
      </WrapLabel>
    </Tooltip>
  );
};
