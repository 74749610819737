import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PageTitle, UILoading } from '@components';
import { userSelector } from '@store';

import { Box, Title, Container, Wrapper } from './styles';
import { useAzure, useEmail, useGoogle } from './hooks';

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const user = useSelector(userSelector.selectUserData);
  const { render: renderEmail, renderProvider: renderProviderEmail, renderBackButton } = useEmail();
  const { render: renderAzure, loading: loadingAzure } = useAzure();
  const { render: renderGoogle, loading: loadingGoogle } = useGoogle();
  const loading = loadingAzure || loadingGoogle;
  const env = process.env.REACT_APP_ENV;

  useEffect(() => {
    if (user) navigator('/project/list');
  }, [user]);

  return (
    <Wrapper>
      <PageTitle>{t('common.login')}</PageTitle>
      <Box>
        {renderBackButton()}
        <Title>Sign in</Title>
        <Container>
          {renderProviderEmail(
            <>
              {renderEmail()}
              {env !== 'dev' && renderAzure()}
              {env !== 'dev' && renderGoogle()}
            </>
          )}
        </Container>
      </Box>
      {loading && (
        <div className="loading-app">
          <UILoading />
        </div>
      )}
    </Wrapper>
  );
};

export default LoginPage;
