import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, message } from 'antd';

import { TRAIN_PARAMS } from '@constants';
import { useCheckRedirect } from '@helpers/hooks';
import { ParamsCreate } from '@store/pipelines/type';
import { architechturesSelector, pipelinesActions, pipelinesSelector, projectActions, projectSelector } from '@store';
import { ArchitechturesModel } from '@types';

import { FormType, Props } from '../types';

export const useModalHandle = (props: Props) => {
  const [form] = Form.useForm<FormType>();
  const dispatch = useDispatch<AppDispatch>();
  const projectItem = useSelector(projectSelector.selectItem);
  const architechturesList = useSelector(architechturesSelector.selectList);
  const transactionCreate = useSelector(pipelinesSelector.selectCreateTransaction);
  const [step, setStep] = useState(0);
  const [submitData, setSubmitData] = useState<ParamsCreate>();
  const [architectureId, setArchitectureId] = useState<string>();
  const [architecture, setArchitecture] = useState<ArchitechturesModel>();
  const { approve, block, check, renderModal } = useCheckRedirect();

  const defaultValues: Partial<FormType> = {
    // name: `New model - ${moment.unix(Date.now() / 1000).format('MMMM Do YYYY, h:mm A')}`,
  };
  Object.keys(TRAIN_PARAMS).forEach((o) => (defaultValues[o as keyof FormType] = TRAIN_PARAMS[o].default));

  const onInvisible = async () => {
    props.setVisible(false);
  };

  const onFormSubmit = async (data: FormType) => {
    if (projectItem.result?.id) {
      const dataString: any = {};
      Object.keys(data).forEach((o) => (dataString[o] = data[o as keyof typeof data]?.toString()));

      setSubmitData({
        ...dataString,
        project_id: projectItem.result.id,
      });
      setStep(1);
    }
  };

  const createPipeline = async () => {
    await new Promise((res) => dispatch(pipelinesActions.create({ params: submitData, onSuccess: res })));
    if (projectItem.result?.id) dispatch(projectActions.updateWorkflow({ params: { id: projectItem.result?.id, step: ['train-model'] } }));
    approve();
    props.onDone();
    onInvisible();
    message.success('Create train model pipeline successfully.');
  };

  const onFormChange = (changedValues: any, values: FormType) => {
    setArchitectureId((state) => {
      if (state !== values.architecture_id) return values.architecture_id;
      return state;
    });

    block();
  };

  const onChangeStep = (value: number) => {
    setStep(value);
  };

  const onCancel = async () => {
    await check();
    onInvisible();
  };

  const onOk = () => {
    if (step === 0) form.submit();
    else if (step === 1) createPipeline();
  };

  const onStepClick = (current: number) => {
    if (current > step) return;
    setStep(current);
  };

  useEffect(() => {
    if (!architectureId || !architechturesList.result?.items?.length) return;
    const dataFind = architechturesList.result.items.find((o) => o.id === architectureId);
    if (dataFind) setArchitecture(dataFind);
  }, [architectureId, architechturesList]);

  useEffect(() => {
    if (props.visible) return;
    setStep(0);
    setSubmitData(undefined);
    setArchitectureId(undefined);
    setArchitecture(undefined);
    form.resetFields();
  }, [props.visible]);

  return {
    architecture,
    form,
    step,
    submitData,
    defaultValues,
    onInvisible,
    renderModal,
    onFormSubmit,
    createPipeline,
    onFormChange,
    onChangeStep,
    onCancel,
    onOk,
    onStepClick,
    transactionCreate,
  };
};
