import { DeviceSvg, MemberSvg, ModelSvg, ProjectSvg } from '@assets/svgs';

export const config = [
  {
    key: '/project/list',
    title: 'Projects',
    icon: <ProjectSvg />,
  },
  {
    key: '/workspace/devices',
    title: 'Devices',
    icon: <DeviceSvg />,
  },
  {
    key: '/workspace/members',
    title: 'Members',
    icon: <MemberSvg />,
  },
  {
    key: '/workspace/architectures',
    title: 'Models',
    icon: <ModelSvg />,
  },
];
