import React from 'react';
import { Form, Steps as UISteps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { useSelect, UseSelectFetch } from '@helpers/hooks';
import { exportedDatasetActions, projectSelector } from '@store';
import { ExportedDatasetModel } from '@types';

import { Props } from './types';
import { useModalHandle } from './hooks';
import { Wrapper, WrapperBody, WrapperJob, Steps, Content } from './styles';
import { FormCommon, InfoCommon, SupervisedMotobikeForm, SupervisedMotobikeInfo, UnsupervisedGrinderForm, UnsupervisedGrinderInfo } from './components';

const { Step } = UISteps;

export const ModalTrain: React.FC<Props> = ({ ...props }) => {
  const dispatch = useDispatch<AppDispatch>();
  const project = useSelector(projectSelector.selectItem);
  const { architecture, form, step, submitData, defaultValues, transactionCreate, renderModal, onCancel, onChangeStep, onFormChange, onFormSubmit, onOk, onStepClick } = useModalHandle(props);

  const fetch: UseSelectFetch<ExportedDatasetModel> = async ({ page, size, search }) => {
    if (!project.result?.id) throw new Error();
    return new Promise((res) => dispatch(exportedDatasetActions.fetchManyDone({ params: { project_id: project.result?.id, page, size, ...search }, onSuccess: res })));
  };
  const datasetHook = useSelect<ExportedDatasetModel>({ fetch });

  return (
    <Wrapper {...props} title="Train new model" onCancel={onCancel} onOk={onOk} okButtonProps={{ htmlType: 'submit', form: 'train', disabled: !Boolean(architecture) }} okText={step === 1 ? 'Run' : 'Next'} confirmLoading={transactionCreate.loading} width={800} centered destroyOnClose>
      <WrapperBody>
        <Steps size="small" current={step} onChange={onChangeStep}>
          {['1.Model and dataset', '2.Start job'].map((o, i) => (
            <Step key={i} title={o} icon={<></>} onStepClick={onStepClick} disabled={step < i} />
          ))}
        </Steps>
        <Content>
          {step === 0 ? (
            <>
              <Form form={form} name="train" onFinish={onFormSubmit} onValuesChange={onFormChange} initialValues={defaultValues} layout="vertical">
                <FormCommon datasetHook={datasetHook} />
                {architecture?.model_code === 'supervised_motorbike_v1' ? <SupervisedMotobikeForm /> : architecture?.model_code === 'unsupervised_grinder_v1' ? <UnsupervisedGrinderForm /> : null}
              </Form>
            </>
          ) : (
            <WrapperJob>
              <InfoCommon data={submitData} datasetHook={datasetHook} />
              {architecture?.model_code === 'supervised_motorbike_v1' ? <SupervisedMotobikeInfo data={submitData} /> : architecture?.model_code === 'unsupervised_grinder_v1' ? <UnsupervisedGrinderInfo data={submitData} /> : null}
            </WrapperJob>
          )}
        </Content>
      </WrapperBody>
      {renderModal()}
    </Wrapper>
  );
};
