import { Form, Input, InputNumber, Select } from 'antd';

import { TRAIN_PARAMS } from '@constants';
import { ParamsCreate } from '@store/pipelines/type';

import { ItemInfo, ItemInfoContent, ItemInfoTitle } from '../styles';
import { generateRangeRule, onWheel } from '../utils';

import { LabelHint } from './LabelHint';

export const SupervisedMotobikeForm: React.FC = () => (
  <>
    <Form.Item name="num_epoch" className="material-input" label={<LabelHint title="num_epoch" data={TRAIN_PARAMS.num_epoch} />} validateFirst rules={[{ required: true, message: 'Num_epoch is required' }, generateRangeRule('num_epoch')]}>
      <Input id="num_epoch" placeholder="Num_epoch" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="batch_size" className="material-input" label={<LabelHint title="batch_size" data={TRAIN_PARAMS.batch_size} />} validateFirst rules={[{ required: true, message: 'Batch_size is required' }, generateRangeRule('batch_size')]}>
      <InputNumber id="batch_size" placeholder="Batch_size" maxLength={50} controls={false} min={1} max={16} />
    </Form.Item>
    <Form.Item name="n_fft" className="material-input" label={<LabelHint title="n_fft" data={TRAIN_PARAMS.n_fft} />} validateFirst rules={[{ required: true, message: 'N_fft is required' }, generateRangeRule('n_fft')]}>
      <Input id="n_fft" placeholder="N_fft" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="n_mels" className="material-input" label={<LabelHint title="n_mels" data={TRAIN_PARAMS.n_mels} />} validateFirst rules={[{ required: true, message: 'N_mels is required' }, generateRangeRule('n_mels')]}>
      <Input id="n_mels" placeholder="N_mels" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="hop_length" className="material-input" label={<LabelHint title="hop_length" data={TRAIN_PARAMS.hop_length} />} validateFirst rules={[{ required: true, message: 'Hop_length is required' }, generateRangeRule('hop_length')]}>
      <Input id="hop_length" placeholder="Hop_length" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="pretrained" className="material-input" label={<LabelHint title="pretrained" data={TRAIN_PARAMS.pretrained} />} validateFirst rules={[{ required: true, message: 'Pretrained is required' }]}>
      <Select placeholder="Pretrained" showArrow>
        {[
          { value: 0, label: 0 },
          { value: 1, label: 1 },
        ].map((o) => (
          <Select.Option key={o.value} value={o.value}>
            {o.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item name="power" className="material-input" label={<LabelHint title="power" data={TRAIN_PARAMS.power} />} validateFirst rules={[{ required: true, message: 'Power is required' }]}>
      <Select placeholder="Power" showArrow>
        {[
          { value: 0, label: 0 },
          { value: 1, label: 1 },
        ].map((o) => (
          <Select.Option key={o.value} value={o.value}>
            {o.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item name="sample_rate" className="material-input" label={<LabelHint title="sample_rate" data={TRAIN_PARAMS.sample_rate} />} validateFirst rules={[{ required: true, message: 'Sample_rate is required' }, generateRangeRule('sample_rate')]}>
      <Input id="sample_rate" placeholder="Sample_rate" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="seed" className="material-input" label={<LabelHint title="seed" data={TRAIN_PARAMS.seed} />} validateFirst rules={[{ required: true, message: 'Seed is required' }, generateRangeRule('seed')]}>
      <Input id="seed" placeholder="Seed" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="folds" className="material-input" hidden label={<LabelHint title="folds" data={TRAIN_PARAMS.folds} />} validateFirst rules={[{ required: true, message: 'Folds is required' }, generateRangeRule('folds')]}>
      <Input id="folds" placeholder="Folds" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="lr" className="material-input" label={<LabelHint title="lr" data={TRAIN_PARAMS.lr} />} validateFirst rules={[{ required: true, message: 'Lr is required' }, generateRangeRule('lr')]}>
      <InputNumber id="lr" placeholder="Lr" maxLength={50} min={0} max={1} controls={false} />
    </Form.Item>
    <Form.Item name="num_workers" className="material-input" label={<LabelHint title="num_workers" data={TRAIN_PARAMS.num_workers} />} validateFirst rules={[{ required: true, message: 'Num_workers is required' }, generateRangeRule('num_workers')]}>
      <Input id="num_workers" placeholder="Num_workers" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="early_stop" className="material-input" label={<LabelHint title="early_stop" data={TRAIN_PARAMS.early_stop} />} validateFirst rules={[{ required: true, message: 'Early_stop is required' }, generateRangeRule('early_stop')]}>
      <Input id="early_stop" placeholder="Early_stop" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
  </>
);

export const SupervisedMotobikeInfo: React.FC<{ data?: ParamsCreate }> = ({ data }) => (
  <>
    <ItemInfo>
      <ItemInfoTitle>Epoch</ItemInfoTitle>
      <ItemInfoContent>{data?.num_epoch}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Batch size</ItemInfoTitle>
      <ItemInfoContent>{data?.batch_size}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>N_fft</ItemInfoTitle>
      <ItemInfoContent>{data?.n_fft}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>N_mels</ItemInfoTitle>
      <ItemInfoContent>{data?.n_mels}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Hop_length</ItemInfoTitle>
      <ItemInfoContent>{data?.hop_length}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Pretrained</ItemInfoTitle>
      <ItemInfoContent>{data?.pretrained}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Power</ItemInfoTitle>
      <ItemInfoContent>{data?.power}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Sample_rate</ItemInfoTitle>
      <ItemInfoContent>{data?.sample_rate}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Seed</ItemInfoTitle>
      <ItemInfoContent>{data?.seed}</ItemInfoContent>
    </ItemInfo>
    {/* <ItemInfo>
      <ItemInfoTitle>folds</ItemInfoTitle>
      <ItemInfoContent>{data?.folds}</ItemInfoContent>
    </ItemInfo> */}
    <ItemInfo>
      <ItemInfoTitle>Lr</ItemInfoTitle>
      <ItemInfoContent>{data?.lr}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Num_workers</ItemInfoTitle>
      <ItemInfoContent>{data?.num_workers}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Early_stop</ItemInfoTitle>
      <ItemInfoContent>{data?.early_stop}</ItemInfoContent>
    </ItemInfo>
  </>
);
