import { Form, Input, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { ParamsCreate } from '@store/pipelines/type';
import { architechturesActions, architechturesSelector } from '@store';
import { useSelect } from '@helpers/hooks';
import { ExportedDatasetModel } from '@types';

import { ItemInfo, ItemInfoContent, ItemInfoTitle } from '../styles';

export const FormCommon: React.FC<{ datasetHook: ReturnType<typeof useSelect<ExportedDatasetModel>> }> = ({ datasetHook }) => {
  const dispatch = useDispatch<AppDispatch>();
  const architectures = useSelector(architechturesSelector.selectList);

  useEffect(() => {
    dispatch(architechturesActions.fetchMany({}));
  }, []);

  return (
    <>
      <Form.Item name="name" className="material-input" label="Model name" validateFirst rules={[{ required: true, message: 'Model name is required' }]}>
        <Input id="name" placeholder="Model name" autoFocus maxLength={50} />
      </Form.Item>
      <Form.Item name="description" className="material-input" label="Description">
        <Input id="description" placeholder="Description" maxLength={255} />
      </Form.Item>
      <Form.Item name="model_dataset_id" className="material-input" label="Dataset" validateFirst rules={[{ required: true, message: 'Tranning dataset is required' }]}>
        <Select {...datasetHook} placeholder="Dataset" notFoundContent={datasetHook.loading ? <Spin size="small" /> : undefined} showSearch allowClear showArrow>
          {datasetHook.options.map((o) => (
            <Select.Option key={o.value} value={o.value}>
              {o.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="architecture_id" className="material-input" label="Model architecture" validateFirst rules={[{ required: true, message: 'Model architecture is required' }]}>
        <Select placeholder="Model architecture" loading={architectures.loading} notFoundContent={architectures.loading ? <Spin size="small" /> : undefined} showSearch allowClear showArrow>
          {(architectures.result?.items || []).map((o) => (
            <Select.Option key={o.id} value={o.id}>
              {o.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export const InfoCommon: React.FC<{ data?: ParamsCreate; datasetHook: ReturnType<typeof useSelect<ExportedDatasetModel>> }> = ({ data, datasetHook }) => {
  const architectures = useSelector(architechturesSelector.selectList);

  return (
    <>
      <ItemInfo>
        <ItemInfoTitle>Name</ItemInfoTitle>
        <ItemInfoContent>{data?.name}</ItemInfoContent>
      </ItemInfo>
      <ItemInfo>
        <ItemInfoTitle>Description</ItemInfoTitle>
        <ItemInfoContent>{data?.description}</ItemInfoContent>
      </ItemInfo>
      <ItemInfo>
        <ItemInfoTitle>Trainning dataset</ItemInfoTitle>
        <ItemInfoContent>{datasetHook.options.find((o) => data?.model_dataset_id === o.value)?.label}</ItemInfoContent>
      </ItemInfo>
      <ItemInfo>
        <ItemInfoTitle>Architecture</ItemInfoTitle>
        <ItemInfoContent>{architectures.result.items.find((o) => data?.architecture_id === o.id)?.name}</ItemInfoContent>
      </ItemInfo>
    </>
  );
};
