import { TrainItem } from '@types';

export const TRAIN_PARAMS: Record<string, TrainItem> = {
  pretrained: {
    hint: 'Using pretrained model when training',
    default: 1,
  },
  power: {
    hint: 'Using exponent when training',
    default: 1,
  },
  num_epoch: {
    hint: 'Number of epochs to train',
    default: 1,
    range: [1, 300],
  },
  batch_size: {
    hint: 'Number of samples',
    default: 16,
    range: [3, 16],
  },
  n_mels: {
    hint: 'Number of Mel bands to generate',
    default: 128,
    range: [1, 1024],
  },
  hop_length: {
    hint: 'Length of the FFT window',
    default: 512,
    range: [1, 1024],
  },
  sample_rate: {
    hint: 'Sample rate of the signal',
    default: 44100,
    range: [1, 100000],
  },
  n_fft: {
    hint: 'Length of the FFT window',
    default: 1024,
    range: [1, 4000],
  },
  seed: {
    hint: 'Random seed for the solution',
    default: 2022,
    range: [0, 1000000],
  },
  folds: {
    hint: 'Number of K folds',
    default: 5,
    range: [1, 10],
  },
  lr: {
    hint: 'Learning Rate',
    default: 0.001,
    range: [0, 1],
  },
  num_workers: {
    hint: 'Number of subprocesses to use for data loading',
    default: 4,
    range: [1, 8],
  },
  early_stop: {
    hint: 'Number of epoch to early stop training',
    default: 10,
    range: [1, 300],
  },

  mixture: {
    hint: 'Use mixture features or not',
    default: 1,
    range: [0, 1],
  },
  use_file: {
    hint: 'Add noise or not',
    default: 0,
    range: [0, 1],
  },
  feature_n_mels: {
    hint: 'Number of Mel bands to generate',
    default: 128,
    range: [1, 1024],
  },
  feature_frames: {
    hint: 'Number of frames',
    default: 5,
    range: [1, 10],
  },
  feature_n_fft: {
    hint: 'Length of the FFT window',
    default: 1024,
    range: [1, 4000],
  },
  feature_hop_length: {
    hint: 'Number of samples between successive frames',
    default: 512,
    range: [1, 1024],
  },
  feature_power: {
    hint: 'Exponent for the magnitude melspectrogram. e.g., 1 for energy, 2 for power, etc.',
    default: 2.0,
    range: [1, 3],
  },
  fit_compile_optimizer: {
    hint: 'Optiomizer method',
    default: 'adam',
  },
  fit_compile_loss: {
    hint: 'Loss algorithm',
    default: 'mean_squared_error',
  },
  fit_epochs: {
    hint: 'Number of epoches',
    default: 1,
    range: [1, 2000],
  },
  fit_batch_size: {
    hint: 'Number of data in 1 epoch',
    default: 512,
    range: [2, 512],
  },
  fit_shuffle: {
    hint: 'Shuffle data or not',
    default: 1,
    range: [0, 1],
  },
  fit_validation_split: {
    hint: 'Ratio of the validation data',
    default: 0.2,
    range: [0.1, 0.9],
  },
  fit_verbose: {
    hint: 'Want to check the training progress for each epoch or not',
    default: 1,
    range: [0, 1],
  },
  fit_aug: {
    hint: 'Augment data or not',
    default: 1,
    range: [0, 1],
  },
};
