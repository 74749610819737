import { Button } from 'antd';
import styled from 'styled-components';

import { PipelineStepStatus } from '@types';
import { Card } from '@components';

export const WrapItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  margin-bottom: 16px;
`;

export const TextHead = styled.p`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.7;
  letter-spacing: 0.1px;
  color: black;
  margin-bottom: 6px;
`;

export const TextTitle = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.42;
  letter-spacing: 0.25px;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.87);
`;

export const ContentItem = styled.div<{ status?: PipelineStepStatus }>`
  color: ${({ status, theme }) => (status === 'Cancelled' ? theme.colors.main.warning : status === 'Done' ? theme.colors.main.primary : status === 'Failed' ? theme.colors.main.error : status === 'Running' ? theme.colors.main.info : status === 'Not started' ? theme.colors.text.main : theme.colors.text.main)};
`;

export const Wrapper = styled(Card)`
  max-width: 1150px;
`;

export const Header = styled.div`
  margin-bottom: 14px;
  display: flex;
  align-items: center;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > *:not(:last-child) {
    margin-right: 30px;
  }
`;

export const TextName = styled.p`
  font-size: 1.42rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.15px;
  color: black;
`;

export const ButtonMenu = styled(Button)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 5px 0 5px 5px;
  margin-left: auto;

  &:hover,
  &:focus {
    background-color: unset;
  }
`;
