import { reducer as appReducer } from './app';
import { reducer as userReducer } from './users';
import { reducer as roleSlice } from './roles';
import { reducer as mediaReducer } from './medias';
import { reducer as datasetReducer } from './datasets';
import { reducer as exportedDatasetReducer } from './exportedDatasets';
import { reducer as modelDatasetReducer } from './modelDatasets';
import { reducer as themeReducer } from './theme';
import { reducer as projectReducer } from './projects';
import { reducer as locationReducer } from './locations';
import { reducer as classificationReducer } from './classification';
import { reducer as architechturesReducer } from './architechtures';
import { reducer as pipelinesReducer } from './pipelines';
import { reducer as pipelineStepsReducer } from './pipelineSteps';
import { reducer as devicesReducer } from './devices';
import { reducer as uploadReducer } from './upload';

export const reducer = {
  app: appReducer,
  user: userReducer,
  role: roleSlice,
  media: mediaReducer,
  dataset: datasetReducer,
  exportedDataset: exportedDatasetReducer,
  modelDataset: modelDatasetReducer,
  theme: themeReducer,
  project: projectReducer,
  location: locationReducer,
  classification: classificationReducer,
  architechtures: architechturesReducer,
  pipelines: pipelinesReducer,
  pipelineSteps: pipelineStepsReducer,
  devices: devicesReducer,
  upload: uploadReducer,
};
