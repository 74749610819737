import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state: RootState) => state.user;

export const selector = {
  selectData: createSelector(selectSelf, (state) => state),
  selectSearch: createSelector(selectSelf, (state) => state.search),
  selectItem: createSelector(selectSelf, (state) => state.item),
  selectLogin: createSelector(selectSelf, (state) => state.login),
  selectLoginGoogle: createSelector(selectSelf, (state) => state.loginGoogle),
  selectLoginEmail: createSelector(selectSelf, (state) => state.loginEmail),
  selectUserData: createSelector(selectSelf, (state) => state.user),
  selectChangePassword: createSelector(selectSelf, (state) => state.changePassword),
};
