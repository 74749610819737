import { Button, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import { Breadcrumb, BreadcrumbItem, Empty, LoadingContainer, PageTitle } from '@components';
import { architechturesActions, pipelinesActions, projectSelector, roleSelector } from '@store';
import { PipelineModel } from '@types';
import { useScrollContent } from '@helpers/hooks';

import { Wrapper, PageMainHeading } from '../../styles';

import { Content, List, WrapActions, TextDesc } from './styles';
import { ModelItem, ModalTrain } from './components';

export const ModelsPipelinePage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const projectItem = useSelector(projectSelector.selectItem);
  const [showModalTrain, setShowModalTrain] = useState(false);
  const role = useSelector(roleSelector.selectUserRole);
  const isCanUpdate = role.result?.role === 'admin';

  const onClickTrain = () => setShowModalTrain(true);

  const fetch = useCallback(
    (page: number, size: number) =>
      new Promise((res) => {
        if (projectItem.result?.id) dispatch(pipelinesActions.fetchMany({ params: { project_id: projectItem.result?.id || '', page, size }, onSuccess: res }));
      }) as any,
    [projectItem.result?.id]
  );

  const { loading, data, fetchData } = useScrollContent<PipelineModel>({
    size: 10,
    queries: '.ant-layout-content',
    handle: async ({ fetchMore, loading: _loading }) => {
      if (!_loading) await fetchMore();
    },
    fetch: fetch,
    handleData: (items) => (state) => {
      const nstate = items.filter((o) => state.every((oo) => oo.id !== o.id));
      return [...state, ...nstate];
    },
  });

  useEffect(() => {
    dispatch(architechturesActions.fetchMany({}));
  }, []);

  return (
    <Wrapper>
      <PageTitle>Model pipelines</PageTitle>
      <Breadcrumb>
        <BreadcrumbItem href="/workspace">Home</BreadcrumbItem>
        <BreadcrumbItem href="/workspace/models">Models</BreadcrumbItem>
        <BreadcrumbItem>Pipeline</BreadcrumbItem>
      </Breadcrumb>
      <PageMainHeading>Model pipelines</PageMainHeading>
      <Content>
        <TextDesc>
          With the <span>Models</span> module you can create your own artificial intelligence solution from your own data for the anomaly detection problem
        </TextDesc>
        <WrapActions>
          {isCanUpdate && (
            <Button type="primary" onClick={onClickTrain}>
              Train new model
            </Button>
          )}
        </WrapActions>
        {!data?.length ? (
          <Empty loading={loading} />
        ) : (
          <>
            <List>
              {data.map((o) => (
                <ModelItem key={o.id} data={o} fetchData={fetchData} />
              ))}
            </List>
            {loading && (
              <LoadingContainer>
                <Spin />
              </LoadingContainer>
            )}
          </>
        )}
      </Content>
      <ModalTrain visible={showModalTrain} setVisible={setShowModalTrain} onDone={fetchData} />
    </Wrapper>
  );
};
