import { Form, Input } from 'antd';

import { TRAIN_PARAMS } from '@constants';
import { ParamsCreate } from '@store/pipelines/type';

import { ItemInfo, ItemInfoContent, ItemInfoTitle } from '../styles';
import { generateRangeRule, onWheel } from '../utils';

import { LabelHint } from './LabelHint';

export const UnsupervisedGrinderForm: React.FC = () => (
  <>
    <Form.Item name="mixture" className="material-input" label={<LabelHint title="mixture" data={TRAIN_PARAMS.mixture} />} validateFirst rules={[{ required: true, message: 'Mixture is required' }, generateRangeRule('mixture')]}>
      <Input id="mixture" placeholder="Mixture" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="use_file" className="material-input" label={<LabelHint title="use_file" data={TRAIN_PARAMS.use_file} />} validateFirst rules={[{ required: true, message: 'Use_file is required' }, generateRangeRule('use_file')]}>
      <Input id="use_file" placeholder="Use_file" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="seed" className="material-input" label={<LabelHint title="seed" data={TRAIN_PARAMS.seed} />} validateFirst rules={[{ required: true, message: 'Seed is required' }, generateRangeRule('seed')]}>
      <Input id="seed" placeholder="Seed" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="feature_n_mels" className="material-input" label={<LabelHint title="feature_n_mels" data={TRAIN_PARAMS.feature_n_mels} />} validateFirst rules={[{ required: true, message: 'Feature_n_mels is required' }, generateRangeRule('feature_n_mels')]}>
      <Input id="feature_n_mels" placeholder="Feature_n_mels" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="feature_frames" className="material-input" label={<LabelHint title="feature_frames" data={TRAIN_PARAMS.feature_frames} />} validateFirst rules={[{ required: true, message: 'Feature_frames is required' }, generateRangeRule('feature_frames')]}>
      <Input id="feature_frames" placeholder="Feature_frames" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="feature_n_fft" className="material-input" label={<LabelHint title="feature_n_fft" data={TRAIN_PARAMS.feature_n_fft} />} validateFirst rules={[{ required: true, message: 'Feature_n_fft is required' }, generateRangeRule('feature_n_fft')]}>
      <Input id="feature_n_fft" placeholder="Feature_n_fft" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="feature_hop_length" className="material-input" label={<LabelHint title="feature_hop_length" data={TRAIN_PARAMS.feature_hop_length} />} validateFirst rules={[{ required: true, message: 'Feature_hop_length is required' }, generateRangeRule('feature_hop_length')]}>
      <Input id="feature_hop_length" placeholder="Feature_hop_length" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="feature_power" className="material-input" label={<LabelHint title="feature_power" data={TRAIN_PARAMS.feature_power} />} validateFirst rules={[{ required: true, message: 'Feature_power is required' }, generateRangeRule('feature_power')]}>
      <Input id="feature_power" placeholder="Feature_power" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="fit_compile_optimizer" className="material-input" label={<LabelHint title="fit_compile_optimizer" data={TRAIN_PARAMS.fit_compile_optimizer} />} validateFirst rules={[{ required: true, message: 'Fit_compile_optimizer is required' }]}>
      <Input id="fit_compile_optimizer" placeholder="Fit_compile_optimizer" maxLength={50} disabled />
    </Form.Item>
    <Form.Item name="fit_compile_loss" className="material-input" label={<LabelHint title="fit_compile_loss" data={TRAIN_PARAMS.fit_compile_loss} />} validateFirst rules={[{ required: true, message: 'Fit_compile_loss is required' }]}>
      <Input id="fit_compile_loss" placeholder="Fit_compile_loss" maxLength={50} disabled />
    </Form.Item>
    <Form.Item name="fit_epochs" className="material-input" label={<LabelHint title="fit_epochs" data={TRAIN_PARAMS.fit_epochs} />} validateFirst rules={[{ required: true, message: 'Fit_epochs is required' }, generateRangeRule('fit_epochs')]}>
      <Input id="fit_epochs" placeholder="Fit_epochs" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="fit_batch_size" className="material-input" label={<LabelHint title="fit_batch_size" data={TRAIN_PARAMS.fit_batch_size} />} validateFirst rules={[{ required: true, message: 'Fit_batch_size is required' }, generateRangeRule('fit_batch_size')]}>
      <Input id="fit_batch_size" placeholder="Fit_batch_size" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="fit_shuffle" className="material-input" label={<LabelHint title="fit_shuffle" data={TRAIN_PARAMS.fit_shuffle} />} validateFirst rules={[{ required: true, message: 'Fit_shuffle is required' }, generateRangeRule('fit_shuffle')]}>
      <Input id="fit_shuffle" placeholder="Fit_shuffle" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="fit_validation_split" className="material-input" label={<LabelHint title="fit_validation_split" data={TRAIN_PARAMS.fit_validation_split} />} validateFirst rules={[{ required: true, message: 'Fit_validation_split is required' }, generateRangeRule('fit_validation_split')]}>
      <Input id="fit_validation_split" placeholder="Fit_validation_split" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="fit_verbose" className="material-input" label={<LabelHint title="fit_verbose" data={TRAIN_PARAMS.fit_verbose} />} validateFirst rules={[{ required: true, message: 'Fit_verbose is required' }, generateRangeRule('fit_verbose')]}>
      <Input id="fit_verbose" placeholder="Fit_verbose" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
    <Form.Item name="fit_aug" className="material-input" label={<LabelHint title="fit_aug" data={TRAIN_PARAMS.fit_aug} />} validateFirst rules={[{ required: true, message: 'Fit_aug is required' }, generateRangeRule('fit_aug')]}>
      <Input id="fit_aug" placeholder="Fit_aug" maxLength={50} type="number" onWheel={onWheel} />
    </Form.Item>
  </>
);

export const UnsupervisedGrinderInfo: React.FC<{ data?: ParamsCreate }> = ({ data }) => (
  <>
    <ItemInfo>
      <ItemInfoTitle>Mixture</ItemInfoTitle>
      <ItemInfoContent>{data?.mixture}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Use_file</ItemInfoTitle>
      <ItemInfoContent>{data?.use_file}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Seed</ItemInfoTitle>
      <ItemInfoContent>{data?.seed}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Feature_n_mels</ItemInfoTitle>
      <ItemInfoContent>{data?.feature_n_mels}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Feature_frames</ItemInfoTitle>
      <ItemInfoContent>{data?.feature_frames}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Feature_n_fft</ItemInfoTitle>
      <ItemInfoContent>{data?.feature_n_fft}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Feature_hop_length</ItemInfoTitle>
      <ItemInfoContent>{data?.feature_hop_length}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Feature_power</ItemInfoTitle>
      <ItemInfoContent>{data?.feature_power}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Fit_compile_optimizer</ItemInfoTitle>
      <ItemInfoContent>{data?.fit_compile_optimizer}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Fit_compile_loss</ItemInfoTitle>
      <ItemInfoContent>{data?.fit_compile_loss}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Fit_epochs</ItemInfoTitle>
      <ItemInfoContent>{data?.fit_epochs}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Fit_batch_size</ItemInfoTitle>
      <ItemInfoContent>{data?.fit_batch_size}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Fit_shuffle</ItemInfoTitle>
      <ItemInfoContent>{data?.fit_shuffle}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Fit_validation_split</ItemInfoTitle>
      <ItemInfoContent>{data?.fit_validation_split}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Fit_verbose</ItemInfoTitle>
      <ItemInfoContent>{data?.fit_verbose}</ItemInfoContent>
    </ItemInfo>
    <ItemInfo>
      <ItemInfoTitle>Fit_aug</ItemInfoTitle>
      <ItemInfoContent>{data?.fit_aug}</ItemInfoContent>
    </ItemInfo>
  </>
);
