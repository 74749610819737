import moment from 'moment';
import { Col, Row, Spin, Steps } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { PageTitle, DetailPack, LoadingContainer } from '@components';
import { pipelineStepsActions } from '@store';
import { snakeCaseToTitle } from '@helpers/utils';
import { Pagination, PipelineInfo, PipelineStepModel, Response, ResultInfo } from '@types';

import { Wrapper, PageMainHeading } from '../../styles';

import { Back, Content, WrapperBody, WrapperStep } from './styles';

const { Step } = Steps;

const tabs = ['preprocessing', 'training', 'evaluation'];

export const ModelsDetailPage = () => {
  const params = useParams<{ pipelineId: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const [current, setCurrent] = useState(0);
  const [list, setList] = useState<PipelineStepModel[]>([]);
  const dataStep = list.find((o) => o.step_name === tabs[current]);
  const refAPIInterval = useRef<any>();

  const clearAPIInterval = () => {
    if (refAPIInterval.current) {
      clearInterval(refAPIInterval.current);
      refAPIInterval.current = undefined;
    }
  };

  const fetchData = async () => {
    // if (!params.pipelineId || !projectItem.result?.id) return;
    if (!params.pipelineId) return;
    const response: Response<Pagination<PipelineStepModel>> = await new Promise((res) => dispatch(pipelineStepsActions.fetchMany({ params: { pipeline_id: params.pipelineId || '' }, onSuccess: res })));

    const arr = response.data.items;
    setList(arr);

    if (arr && arr.length === tabs.length && (arr.some((o) => o.status === 'Failed' || o.status === 'Cancelled') || arr.every((o) => o.status === 'Done'))) clearAPIInterval();
  };

  const createAPIInterval = () => {
    clearAPIInterval();
    refAPIInterval.current = setInterval(fetchData, 1000);
  };

  const onChange = (value: number) => {
    const data = list.find((o) => o.step_name === tabs[value]);
    if (data && data.status === 'Cancelled') return;
    setCurrent(value);
  };

  useEffect(() => {
    if (!params.pipelineId) return;
    fetchData();
    createAPIInterval();
    return () => clearAPIInterval();
  }, [params]);

  return (
    <Wrapper>
      <Back />
      <PageTitle>Job details</PageTitle>
      <PageMainHeading>Job details</PageMainHeading>
      <WrapperBody>
        <WrapperStep>
          <Steps type="default" size="small" current={current} onChange={onChange} className="site-navigation-steps">
            {tabs.map((o, i) => {
              const data = list.find((oo) => oo.step_name === o);
              const title = snakeCaseToTitle(o);
              const timeEnd = data?.status === 'Running' ? moment(new Date()) : data ? moment(data.updated_at * 1000) : undefined;
              const timeStart = data ? moment(data.created_at * 1000) : undefined;
              const time = !data || !timeEnd || data?.status === 'Not started' ? '' : moment.utc(moment(timeEnd.diff(timeStart))).format('HH:mm:ss');
              const status = data?.status === 'Done' ? 'finish' : data?.status === 'Running' ? 'process' : data?.status === 'Cancelled' || data?.status === 'Failed' ? 'error' : 'wait';
              return <Step key={i} stepIndex={i} title={title} subTitle={time} status={status} />;
            })}
          </Steps>
        </WrapperStep>
        <Content>
          {dataStep ? (
            <Row gutter={[24, 24]}>
              {Boolean(dataStep.error_message) && (
                <Col xs={24}>
                  <DetailPack.WrapError message="Error" description={dataStep.error_message} type="error" showIcon />
                </Col>
              )}
              <Col xs={24} xl={12}>
                <DetailPack.Container>
                  <DetailPack.TextTitle>Job info</DetailPack.TextTitle>
                  <DetailPack.Line>
                    <DetailPack.TextCategory>Job ID</DetailPack.TextCategory>
                    <DetailPack.TextDetail copyable>{dataStep.id}</DetailPack.TextDetail>
                  </DetailPack.Line>
                  <DetailPack.Line>
                    <DetailPack.TextCategory>Step</DetailPack.TextCategory>
                    <DetailPack.TextDetail>{snakeCaseToTitle(dataStep.step_name)}</DetailPack.TextDetail>
                  </DetailPack.Line>
                  <DetailPack.Line>
                    <DetailPack.TextCategory>Status</DetailPack.TextCategory>
                    <DetailPack.TextDetail status={dataStep.status}>{snakeCaseToTitle(dataStep.status)}</DetailPack.TextDetail>
                  </DetailPack.Line>
                  <DetailPack.Line>
                    <DetailPack.TextCategory>Created at</DetailPack.TextCategory>
                    <DetailPack.TextDetail>{moment.unix(dataStep.created_at).format('MMMM Do YYYY, h:mm A')}</DetailPack.TextDetail>
                  </DetailPack.Line>
                  {Boolean(dataStep.script_version) && (
                    <DetailPack.Line>
                      <DetailPack.TextCategory>Script version</DetailPack.TextCategory>
                      <DetailPack.TextDetail>{dataStep.script_version}</DetailPack.TextDetail>
                    </DetailPack.Line>
                  )}
                </DetailPack.Container>
                {Boolean(dataStep.tags) && (
                  <DetailPack.Container>
                    <DetailPack.TextTitle>Tags</DetailPack.TextTitle>
                    {Object.keys(dataStep.tags).map((key) => {
                      const metric = dataStep.tags[key as keyof PipelineStepModel['tags']];
                      if (!metric) return null;
                      return (
                        <DetailPack.Line key={key}>
                          <DetailPack.TextCategory>{snakeCaseToTitle(key)}</DetailPack.TextCategory>
                          <DetailPack.TextDetail>{metric.toString()}</DetailPack.TextDetail>
                        </DetailPack.Line>
                      );
                    })}
                  </DetailPack.Container>
                )}
                {Boolean(dataStep.dataset_id) && (
                  <DetailPack.Container>
                    <DetailPack.TextTitle>Data info</DetailPack.TextTitle>
                    <DetailPack.Line>
                      <DetailPack.TextCategory>Dataset ID</DetailPack.TextCategory>
                      <DetailPack.TextDetail>{dataStep.dataset_id}</DetailPack.TextDetail>
                    </DetailPack.Line>
                  </DetailPack.Container>
                )}
              </Col>
              <Col xs={24} lg={12}>
                {Boolean(dataStep.step_info) && Boolean(Object.keys(dataStep.step_info).length) && (
                  <DetailPack.Container>
                    <DetailPack.TextTitle>Step info</DetailPack.TextTitle>
                    {Object.keys(dataStep.step_info).map((key) => {
                      const metric = dataStep.step_info[key as keyof PipelineInfo];
                      if (!metric) return null;
                      return (
                        <DetailPack.Line key={key}>
                          <DetailPack.TextCategory>{snakeCaseToTitle(key)}</DetailPack.TextCategory>
                          <DetailPack.TextDetail>{metric.toString()}</DetailPack.TextDetail>
                        </DetailPack.Line>
                      );
                    })}
                  </DetailPack.Container>
                )}
                {Boolean(dataStep.result_info) && Boolean(Object.keys(dataStep.result_info).length) && (
                  <DetailPack.Container>
                    <DetailPack.TextTitle>Result info</DetailPack.TextTitle>
                    {Object.keys(dataStep.result_info).map((key) => {
                      const metric = dataStep.result_info[key as keyof ResultInfo];
                      if (!metric) return null;
                      const arrs = typeof metric === 'object' ? Object.keys(metric).map((mkey) => ({ key: mkey, value: metric[mkey as keyof typeof metric] })) : [{ key, value: metric }];
                      return arrs.map((o) => (
                        <DetailPack.Line key={o.key}>
                          <DetailPack.TextCategory>{snakeCaseToTitle(o.key)}</DetailPack.TextCategory>
                          <DetailPack.TextDetail>{o.value}</DetailPack.TextDetail>
                        </DetailPack.Line>
                      ));
                    })}
                  </DetailPack.Container>
                )}
              </Col>
            </Row>
          ) : (
            <LoadingContainer>
              <Spin />
            </LoadingContainer>
          )}
        </Content>
      </WrapperBody>
    </Wrapper>
  );
};
