import { useCallback, useEffect, useRef, useState } from 'react';

import { Pagination, Response } from '@types';

type Props<RecordType> = {
  queries: string;
  size: number;
  fetch: (page: number, size: number) => Promise<Response<Pagination<RecordType>>>;
  handleData: (responseItem: RecordType[]) => (state: RecordType[]) => RecordType[];
  handle: (props: { loading: boolean; fetchMore: () => Promise<void> }) => Promise<void>;
};

export const useScrollContent = <RecordType extends object>(props: Props<RecordType>) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<RecordType[]>([]);
  const pagination = useRef<Pagination['pagination']>({ currentPage: 0, totalPages: 0, totalRecords: 0 });

  const fetchData = async () => {
    setLoading(true);
    const response: Response<Pagination<RecordType>> = await props.fetch(1, props.size);
    pagination.current = response.data.pagination;
    setData(props.handleData(response.data.items)([]));

    setLoading(false);
  };

  const fetchMoreData = useCallback(async () => {
    const nextPage = Math.floor(data.length / 10) + 1;
    if (data.length >= pagination.current.totalRecords) return;

    setLoading(true);
    const response: Response<Pagination<RecordType>> = await props.fetch(nextPage, props.size);

    pagination.current = response.data.pagination;
    setData(props.handleData(response.data.items));

    setLoading(false);
  }, [data]);

  useEffect(() => {
    const elem = document.querySelector(props.queries);
    if (!elem) return;
    const scrollHandle = (event: Event) => {
      const element: any = event.target;
      if (element && Math.abs(element.scrollHeight - (element.scrollTop + element.offsetHeight)) <= 2) {
        props.handle({ fetchMore: fetchMoreData, loading });
      }
    };
    elem.addEventListener('scroll', scrollHandle);
    return () => elem.removeEventListener('scroll', scrollHandle);
  }, [fetchMoreData]);

  useEffect(() => {
    fetchData();
  }, []);

  return {
    data,
    loading,
    fetchData,
  };
};
