import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Input, message, Modal, ModalProps, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import MaskedInput from 'antd-mask-input';

import { devicesActions, devicesSelector, projectSelector } from '@store';
import { DeviceModel } from '@types';
import { useCheckRedirect } from '@helpers/hooks';

type Props = ModalProps & { setVisible: (value: boolean) => void; fetchData: () => Promise<void>; data?: DeviceModel };

type FormType = {
  name: string;
  ip_address: string;
  location: string;
};

const Wrapper = styled(Modal)`
  .ant-modal-body {
    .ant-form-item:last-child {
      margin-bottom: 0;
    }

    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export const ModalAddDevice: React.FC<Props> = (props) => {
  const [form] = Form.useForm<FormType>();
  const [ip, setIP] = useState('');
  const { approve, block, check, renderModal } = useCheckRedirect();
  const dispatch = useDispatch<AppDispatch>();
  const project = useSelector(projectSelector.selectItem);
  const transactionCreate = useSelector(devicesSelector.selectCreateTransaction);
  const transactionUpdate = useSelector(devicesSelector.selectUpdateTransaction);

  const onInvisible = () => {
    props.setVisible(false);
    form.resetFields();
    form.setFieldsValue({ location: project.result?.location });
  };

  const onFinish = async (data: FormType) => {
    if (!props.data) await new Promise((res) => dispatch(devicesActions.create({ params: { ...data, project_id: project.result?.id, location: undefined }, onSuccess: res })));
    else await new Promise((res) => dispatch(devicesActions.update({ params: { ...data, id: props.data?.id, location: undefined }, onSuccess: res })));
    approve();
    props.fetchData();
    onInvisible();

    message.success(`${!props.data ? 'Add' : 'Update'} device ${data.name} successfully.`);
  };

  const onCancel = async () => {
    await check();
    onInvisible();
  };

  const onChange = () => block();

  useEffect(() => {
    if (project) form.setFieldsValue({ location: project.result?.location });
  }, [project]);

  useEffect(() => {
    if (props.data) form.setFieldsValue(props.data);
  }, [props]);

  return (
    <Wrapper {...props} title={`${!props.data ? 'Add' : 'Edit'} device`} centered onCancel={onCancel} confirmLoading={transactionCreate.loading || transactionUpdate.loading} okButtonProps={{ htmlType: 'submit', form: 'register' }} destroyOnClose>
      <Form form={form} name="register" initialValues={{ create_dataset: true }} onFinish={onFinish} onValuesChange={onChange} layout="vertical">
        <Form.Item name="name" className="material-input" label="Device name" rules={[{ required: true, message: 'Device name is required' }]}>
          <Input id="name" placeholder="Device name" autoFocus maxLength={50} />
        </Form.Item>
        <Form.Item
          name="ip_address"
          className="material-input"
          label="IP Address"
          rules={[
            { required: true, message: 'IP Address is required' },
            { pattern: /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/, message: 'Invalid ip address' },
          ]}
        >
          <MaskedInput value={ip} id="ip_address" name="ip_address" mask="0[0][0].0[0][0].0[0][0].0[0][0]" onChange={(e) => setIP(e.target.value)} />
        </Form.Item>
        <Form.Item name="zone" className="material-input" label="Zone" rules={[{ required: true, message: 'Zone is required' }]}>
          <Select placeholder="Zone" showSearch allowClear showArrow>
            {(project.result?.zone || []).map((o) => (
              <Select.Option key={o} value={o}>
                {o}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="location" className="material-input" label="Location">
          <Input id="location" placeholder="Location" maxLength={50} disabled />
        </Form.Item>
        <Form.Item name="create_dataset" valuePropName="checked">
          <Checkbox>Create media group for uploading media from edge device</Checkbox>
        </Form.Item>
      </Form>
      {renderModal()}
    </Wrapper>
  );
};
